<template>
  <v-container class="pa-0 pa-sm-2">
    <div v-if="$store.state.events.eventWasLoaded && event.id">
      <div class="pa-md-4 pa-0" style="background: #f2f7ff; overflow: hidden; border-radius: 20px; position: relative">
        <div
            :style="`z-index: 0; mix-blend-mode: overlay; filter: blur(2px); top: 0; left: 0;background-color: rgba(242,247,255,0.4); background-size: cover; background-position: center; opacity: 1; background-image: url(${event.header_url}); width: 100%; height: 100%; position: absolute;`"/>
        <div>
          <v-container>
            <v-row align="center">
              <v-col cols="12" lg="6" md="6" order="2" order-md="1">
                <div class="mb-3">
                  <UserLinkComponent :user="event.user"/>
                </div>
                <div class="align-content-center">
                  <h1>{{ event.name }}</h1>
                  <p style="white-space: pre-line;" v-html="event.description"/>
                </div>
                <div>
                  <v-btn class="mr-2 mb-2" v-if="event.can_set_points"
                         :to="{name: 'events.expert', params: {id: event.id}}"
                         small outlined dark rounded color="primary">Выставить баллы
                  </v-btn>
                  <v-btn class="mr-2 mb-2" v-if="event.can_admin"
                         :to="{name: 'event.participants', params: {id: event.id}}" small outlined
                         dark rounded color="primary">Настройки
                  </v-btn>

                  <v-btn class="mr-2 mb-2" v-if="event.can_admin"
                         :href="`https://surgu.fra1.digitaloceanspaces.com/event_${event.id}/materials.zip`" small
                         outlined
                         dark rounded color="primary">Скачать материалы
                  </v-btn>
                  <v-btn class="mb-2" v-if="event.id === 31" x-small outlined @click="getCase"
                         dark rounded color="primary">Предложить кейс
                  </v-btn>
                </div>
                <div class="d-flex align-content-end flex-column flex-md-row">
                  <div class="feature mr-sm-2 my-2">
                    <v-btn @click="share"
                           dark outlined rounded small color="green">
                      <v-icon v-if="!shared" left>mdi-share</v-icon>
                      <v-icon v-else left>mdi-check</v-icon>
                      <span v-if="!shared">
                      Поделиться
                    </span>
                      <span v-else>
                      Ссылка скопирована
                    </span>
                    </v-btn>
                  </div>
                  <div v-if="event.is_online" class="feature mr-sm-2 my-2">
                    <v-icon>mdi-web</v-icon>
                    <span class="feature__text ml-2">Онлайн</span>
                  </div>
                  <div v-else-if="event.address && event.address !== 'null'" class="feature">
                    <v-icon>mdi-map</v-icon>
                    <span v-if="event.address && event.address !== 'null'" class="feature__text ml-2">{{
                        event.address
                      }}</span>
                    <span v-if="event.u_place" class="feature__text ml-2">{{ event.u_place.name }}</span>
                  </div>
                  <div class="feature mx-sm-2 my-4">
                    <v-icon>mdi-calendar</v-icon>
                    <span class="feature__text ml-1">
                  {{ $moment.utc(event.start_at).local().format("DD.MM HH:mm") }}
                </span>
                  </div>
                </div>

              </v-col>
              <v-col lg="6" md="6" offset-md="0" order="1" order-lg="2">
                <v-img content-class="rounded" :src="event.header_url" contain height="300"
                       style="border-radius: 20px !important;"
                       width="100%"></v-img>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
      <v-row v-if="event.invitations && event.invitations.length > 0" class="mt-2">
        <v-col cols="12">
          <EventInvitationsWidget class="mb-2"/>
        </v-col>
      </v-row>
      <v-row class="mt-2  mt-md-0">
        <v-col class="py-0" cols="12" lg="6" md="6">
          <EventApplicationFormWidget class="my-4" v-if="!$store.getters.isAuth || event.can_application"/>
          <EventStatusWidget class="my-4" v-if="$store.getters.isAuth"/>
          <TeamControlWidget :team="event.team"
                             v-if="$store.getters.isAuth  && event.can_access && event.teams_allowed && event.team"
                             class="my-4"/>
          <TeamsListWidget v-if="$store.getters.isAuth  && event.teams_allowed" class="my-4"/>
        </v-col>
        <v-col class="py-0" cols="12" lg="6" md="6">
          <EventParticipantsWidget class="my-4"/>
          <EventChatWidget v-if="event.can_access && event.chat_id > 0" class="my-4"/>
          <EventExpertsWidget class="my-4"/>
          <LeadersBoardWidget :teams="$store.state.events.teams" v-if="event.show_points_to_participants" class="my-4"/>
          <EventCasesWidget :cases="event.cases" v-if="$store.getters.isAuth  && event.teams_allowed && event.cases"
                            class="my-4"/>
          <EventRubricsWidget class="my-4" v-if="event.rubrics.length > 0"/>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader style="border-radius: 20px;" v-else
                       v-bind="attrs"
                       type="card-avatar, article, actions"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import EventParticipantsWidget from "@/components/Events/EventParts/Widgets/EventParticipantsWidget.vue";
import EventApplicationFormWidget from "@/components/Events/EventParts/Widgets/EventApplicationFormWidget.vue";
import EventStatusWidget from "@/components/Events/EventParts/Widgets/EventStatusWidget.vue";
import TeamControlWidget from "@/components/Events/EventParts/Widgets/TeamControlWidget.vue";
import EventInvitationsWidget from "@/components/Events/EventParts/Widgets/EventInvitationsWidget.vue";
import EventChatWidget from "@/components/Events/EventParts/Widgets/EventChatWidget.vue";
import TeamsListWidget from "@/components/Events/EventParts/Widgets/TeamsListWidget.vue";
import LeadersBoardWidget from "@/components/Events/EventParts/Widgets/LeadersBoardWidget.vue";
import {createPopup} from "@typeform/embed";
import analytics from "@/api/analytics.js";
import EventCasesWidget from "@/components/Events/EventParts/Widgets/EventCasesWidget.vue";
import EventRubricsWidget from "@/components/Events/EventParts/Widgets/EventRubricsWidget";
import EventExpertsWidget from "@/components/Events/EventParts/Widgets/EventExpertsWidget";

export default {
  name: "EventItemInfo",
  components: {
    EventExpertsWidget,
    EventRubricsWidget,
    EventCasesWidget,
    LeadersBoardWidget,
    TeamsListWidget,
    EventChatWidget,
    EventInvitationsWidget,
    TeamControlWidget,
    EventStatusWidget,
    UserLinkComponent,
    EventParticipantsWidget,
    EventApplicationFormWidget
  },
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    }
  },
  data() {
    return {
      shared: false
    }
  },
  methods: {
    getCase() {
      let {toggle} = createPopup("X5pwTLMW", {
        hidden: {
          event_id: this.event.id,
          user_id: this.$store.state.user.currentUser ? this.$store.state.user.currentUser.id : 0
        }
      });
      toggle();
    },
    share() {
      const url = 'https://student.surgu.ru/events/' + this.event.id;
      if (navigator.share) {
        navigator.share({
          title: this.event.name,
          text: this.event.description,
          url
        })
      } else if (navigator.clipboard) {
        navigator.clipboard.writeText(url)
        this.shared = true;
      }
    }

  },
  beforeRouteLeave(_, __, next){
    this.$store.commit('setEventWasLoaded', false);
    this.$store.commit('setCurrentEvent', null);
    this.$store.commit('setExperts', []);
    this.$store.commit('setEventParticipantsShot', []);
    this.$store.commit('setTeams', []);
    next();
  },
  async mounted() {
    await this.$store.commit('setEventWasLoaded', false);
    await this.$store.dispatch('getEvent', {id: this.$route.params.id})
    document.title = 'АИС | ' + this.event.name;
    await analytics.openEventPage(this.$store.state.user.currentUser.id > 0 ? this.$store.state.user.currentUser.id : 0, this.$route.params.id)
  }
}
</script>

<style scoped>
.feature {
  display: flex;
  align-items: center;
  margin-right: 2em;
}

.features {
  display: flex;
  padding: 20px 0;
  margin-top: 20px;
}

.rounded {
  border-radius: 20px;
  overflow: hidden;
}
</style>
