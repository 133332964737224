<template>
  <v-select :loading="!isLoad" :value="value" @change="$emit('change', $event)" @input="$emit('input', $event)"
            :items="data" item-value="id"
            placeholder="Другой проект">
    <template v-slot:item="data">
      <v-list-item v-bind="data.attrs" v-on="data.on">
        <v-list-item-avatar>
          <v-img :src="data.item.logotype_url"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.title.slice(0, 15) }}...</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.users.slice(0, 2).map((u) => u.name).join(", ") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:selection="data">
      <v-list-item v-if="data !== null" v-bind="data.attrs" v-on="data.on">
        <v-list-item-avatar>
          <v-img :src="data.item.logotype_url"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ data.item.title.slice(0, 20) }}...</v-list-item-title>
          <v-list-item-subtitle>{{ data.item.users.slice(0, 2).map((u) => u.name).join(", ") }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-avatar>
          <v-icon>mdi-star-four-points-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Другой проект</v-list-item-title>
          <v-list-item-subtitle>которого не на АИС</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:prepend-item>
      <v-list-item @click="$emit('input', null)">
        <v-list-item-avatar>
          <v-icon>mdi-star-four-points-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Другой проект</v-list-item-title>
          <v-list-item-subtitle>которого не на АИС</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

  </v-select>
</template>

<script>
import events from "@/api/events.js";

export default {
  name: "SelectProjectForEvent",
  props: ['value', 'event_id', 'currentProject'],
  data() {
    return {
      data: [],
      isLoad: false
    }
  },
  mounted() {
    events.getAvailableProjects(this.event_id).then((r) => {
      this.data = r.data;
      this.currentProject ? this.data.push(this.currentProject) : ''
      this.isLoad = true
    });
  }
}
</script>

<style scoped>

</style>
