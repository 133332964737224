<template>
  <div class="widget">
    <a style="font-size: 12px; text-decoration: none" target="_blank" v-if="team.project_id > 0"
       :href="'/#/projects/'+team.project_id">Перейти к проекту</a>

    <h4>Команда {{ team.name }}</h4>
    <p>{{ team.description }}</p>



    <v-list style="padding: 0" color="transparent">
      <v-list-item v-for="member in team.members" :key="member.id" style="border-bottom: 1px solid #eaeaea; padding-left: 0; padding-right: 0;">
        <v-list-item-content>
          <UserLinkComponent v-if="member.pivot.invitation_id > 0" text="Отправлено приглашение" :user="member"></UserLinkComponent>
          <UserLinkComponent v-else-if="member.pivot.approved == 0 && !event.can_accept_to_tea" text="Ждет одобрения капитана команды" :user="member"></UserLinkComponent>
          <UserLinkComponent v-else-if="member.pivot.approved == 0 && event.can_accept_to_tea" text="Ждет вашего одобрения" :user="member"></UserLinkComponent>
          <UserLinkComponent v-else :user="member"></UserLinkComponent>

        </v-list-item-content>
        <v-list-item-action-text v-if="member.pivot.approved !== 0 || !event.can_accept_to_team">
          {{ member.id === team.user_id ? 'Капитан команды' : 'Участник' }}
        </v-list-item-action-text>
        <template v-else-if="team.can_accept_to_team">
          <v-list-item-action>
            <v-btn :loading="approveMemberLoading" color="green" plain x-small @click="approve(member)">
              <v-icon left>mdi-check</v-icon>
              Одобрить
            </v-btn>
            <v-btn :loading="declineMemberLoading" color="red" plain x-small @click="decline(member)">
              <v-icon left>mdi-close</v-icon>
              Отклонить
            </v-btn>
          </v-list-item-action>
        </template>
        <template
            v-if="member.pivot.approved !== 0  && team.can_accept_to_team && member.id != $store.state.user.currentUser.id">
          <v-list-item-action>
            <v-btn color="red" plain x-small @click="decline(member)">
              <v-icon left>mdi-close</v-icon>
            </v-btn>
          </v-list-item-action>
        </template>
      </v-list-item>
    </v-list>
    <v-btn style="width: 100%" class="mt-2" :loading="joinTeamLoading" @click="joinToTeam" dark depressed  color="primary" rounded
           v-if="team.can_join_to_team">Присоединиться
    </v-btn>
    <div v-if="team.can_upload_files">
      <h5>Логотип</h5>
      <FileUpload :read-only="!team.can_upload_files" @input="saveLogotype" :multiple="false"
                  :value="[{id: 0, type: 'jpeg', user: {}, filename: 'Логотип', link: team.logo}]" class="mt-3 mb-3"/>
    </div>
    <div v-if="team.can_see_files">
      <h5>Файлы</h5>
      <FileUpload :read-only="!team.can_upload_files" @input="saveFiles" v-model="team.files" class="mt-3 mb-3"/>
    </div>
    <v-select @change="saveTeam" v-if="event.can_choose_case && team.can_see_case" :items="event.cases"
              item-text="title"
              item-value="id"
              no-data-text="Нет кейсов"
              :clearable="team.can_choose_case"
              :readonly="!team.can_choose_case"
              placeholder="Выберите кейс"
              label="Кейс"
              v-model="team.event_cases_id"/>



    <div v-if="team && team.can_see_files">
      <h5>Ссылки</h5>
      <v-text-field @change="saveTeam" :clearable="team.can_upload_files" placeholder="Ссылка на репозиторий"
                    v-model="team.repo_link" :readonly="!team.can_upload_files"/>
      <v-text-field @change="saveTeam" :clearable="team.can_upload_files" placeholder="Ссылка на демо"
                    v-model="team.prototype_link" :readonly="!team.can_upload_files"/>
    </div>

    <div v-if="team && team.can_accept_to_team">
      <h5 v-if="event.can_choose_project">Проект</h5>
      <p v-if="event.can_choose_project" style="font-size: 12px">Свяжите мероприятие с проектом на АИС</p>
      <SelectProjectForEvent v-if="event.can_choose_project" :current-project="team.project" @input="saveTeam"
                             :event_id="event.id"
                             v-model="team.project_id"/>

      <h5>Настройки</h5>
      <v-select @change="saveTeam" :items="event.slots"
                :item-text="(item) => $moment.utc(item.start_at).local().format('dd DD.MM HH:mm')"
                item-value="id"
                :item-disabled="(item) => item.teams && item.teams.length > 0 && item.teams[0].id !== team.id"
                no-data-text="Нет доступных слотов"
                :clearable="team.can_choose_timeslot"
                :readonly="!team.can_choose_timeslot"
                placeholder="Выберите время"
                label="Время выступления"
                v-model="team.slot_id"/>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>
            Разрешить вступать в команду
          </v-list-item-title>
          <v-list-item-subtitle>
            разрешить подавать заявки в вашу команду
          </v-list-item-subtitle>

        </v-list-item-content>
        <v-list-item-action>
          <v-switch :loading="openTeamLoading" v-model="team.is_open" :ripple="false" dense inset
                    @change="openTeam($event)"/>
        </v-list-item-action>
      </v-list-item>
    </div>
  </div>
</template>

<script>
import teams from "@/api/teams.js";
import SelectProjectForEvent from "@/components/Events/EventParts/Widgets/SelectProjectForEvent.vue";
import FileUpload from "@/components/Common/FileUpload.vue";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";

export default {
  name: "TeamControlWidget",
  components: {UserLinkComponent, FileUpload, SelectProjectForEvent},
  methods: {
    approve(member) {
      this.approveMemberLoading = true;
      this.$store.dispatch('acceptMember', {team_id: this.team.id, user_id: member.id}).then(() => {
        this.$store.dispatch('getUserTeam', {event_id: this.$store.state.events.currentEvent.id});
        this.$store.dispatch('getEvent', {id: this.event.id}).then(() => {
          this.approveMemberLoading = false;
        })
      })

    },
    saveFiles() {
      this.saveTeam();
    },
    decline(member) {
      this.declineMemberLoading = true;
      this.$store.dispatch('declineMember', {team_id: this.team.id, user_id: member.id}).then(() => {
        this.$store.dispatch('getUserTeam', {event_id: this.$store.state.events.currentEvent.id});
        this.$store.dispatch('getEvent', {id: this.event.id}).then(() => {
          this.declineMemberLoading = false;
        })
      });

    },
    saveTeam() {
      teams.update(this.team.id, this.team).then((r) => {
        this.team = r.data;
        //this.$store.dispatch('getEvent', {id: this.team.event_id});
      });
    },
    saveLogotype(e) {
      if (e[0]) {
        this.team = {...this.team, logo: e[0].link}
      } else {
        this.team = {...this.team, logo: 'https://surgu.fra1.digitaloceanspaces.com/user2/16371808926XE6nux89fU.jpeg'}
      }
      teams.update(this.team.id, this.team).then((r) => {
        this.team = r.data;
        //this.$store.dispatch('getEvent', {id: this.team.event_id});
      });
    },
    openTeam(status) {
      this.openTeamLoading = true;
      teams.openStatus(this.team.id, status).then(() => {
        this.openTeamLoading = false;
      }).catch(() => {
        this.openTeamLoading = false;
        alert("Не удалось открыть/закрыть команду.")
      });
    },
    joinToTeam() {
      if (!this.joinTeamLoading) {
        this.joinTeamLoading = true;
        this.$store.dispatch('joinTeam', {team_id: this.team.id}).then(() => {
          this.$store.dispatch('getEvent', {id: this.team.event_id}).then(() => {
            this.joinTeamLoading = false;
          })
        });
      }
    }
  },
  props: ['team'],
  computed: {
    event() {
      return this.$store.state.events.currentEvent
    },
  },
  data() {
    return {
      openTeamLoading: false,
      joinTeamLoading: false,
      declineMemberLoading: false,
      approveMemberLoading: false,
      files: []
    }
  }
}
</script>

<style scoped>
.widget {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}
</style>
