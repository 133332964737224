<template>
  <div v-if="cases && cases.length > 0" class="widget">
    <h4>Кейсы</h4>
    <div :key="cas_e.id" v-for="cas_e in cases" class="case">
      <div class="case-title">
        {{ cas_e.title }}
      </div>
      <div class="case-link">
        <v-btn target="_blank" :href="cas_e.link" x-small plain text>Открыть</v-btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "EventCasesWidget",
  props: ['cases'],
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    }
  },
}
</script>

<style scoped>
.widget {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}

.case-title {
}

.case {
  display: flex;
  padding: 10px;
  border-radius: 10px;
  justify-content: space-between;
  margin-top: 10px;
}

.case:nth-child(even) {
  background: #edf0fc;
}

.case:hover {
  background: #efefef;
}
</style>
