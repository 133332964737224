<template>
  <div>
    <div v-if="event.can_create_team" class="widget my-4">
      <v-btn
          color="green"
          light
          @click="createTeamDialog = true"
          dark
          style="border-radius: 10px"
          class="mx-auto d-flex"
          elevation="0"
      >
        <v-icon left>mdi-plus</v-icon>
        Создать команду
      </v-btn>
    </div>
    <div class="widget">
      <div v-if="selectedTeam === null">
        <div>
          <h4>Команды мероприятия</h4>
<!--          <v-btn class="mt-2" @click="createTeamDialog = true" v-if="event.can_create_team" rounded color="green"-->
<!--                 outlined dark-->
<!--                 x-small>Создать команду-->
<!--          </v-btn>-->
          <v-dialog max-width="500" v-model="createTeamDialog">
            <TeamCreateEditComponent @close="createTeamDialog = false"/>
          </v-dialog>
          <Empty v-if="!teams.length" class="mx-auto my-7" title="Команд еще нет,"
                 description="но твоя может стать первой"/>
        </div>

        <TeamListItem @click.native="selectedTeam = team" v-for="team in teams" :key="team.id" :team="team"
                      class="mt-3"/>
      </div>
      <div v-else>
        <v-btn x-small plain color="primary" class="mb-2 d-block" @click="selectedTeam = null">
          <v-icon left>mdi-arrow-left</v-icon>
          Назад ко всем командам
        </v-btn>
        <TeamControlWidget :team="selectedTeam"/>
      </div>


    </div>
  </div>
</template>

<script>
import TeamListItem from "@/components/Events/EventParts/Widgets/TeamListItem.vue";
import TeamControlWidget from "@/components/Events/EventParts/Widgets/TeamControlWidget.vue";
import TeamCreateEditComponent from "@/components/OldEvents/TeamCreateEditComponent.vue";
import Empty from "@/components/Common/Empty";

export default {
  name: "TeamsListWidget",
  components: {TeamCreateEditComponent, TeamListItem, TeamControlWidget, Empty},
  watch: {
    event() {
      this.selectedTeam = null;
    }
  },
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    },
    teams() {
      return this.$store.state.events.teams;
    },
  },
  data() {
    return {
      selectedTeam: null,
      createTeamDialog: null
    }
  },
  mounted() {
    this.$store.dispatch('getTeams', {event_id: this.event.id})
    // eslint-disable-next-line no-console
    console.log(this.teams);
  }
}
</script>

<style scoped>
.widget {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}
</style>
