<template>
    <div class="rubrics-widget">
      <h4>Критерии оценивания</h4>
      <p>Эти критерии видны членам жюри при оценке команд</p>
      <EventRubric :show-actions="false" @edit="() => {edit_rubric = rubric; editRubricDialog = true;}" :rubric="rubric"
                   @delete="() => removeRubric(rubric.id)" :key="rubric.id"
                   v-for="rubric in $store.state.events.currentEvent.rubrics"/>
    </div>
</template>

<script>
import EventRubric from "@/components/Events/EventParts/Widgets/EventRubric";

export default {
  name: "EventRubricsWidget",
  components: {EventRubric}
}
</script>

<style scoped>
  .rubrics-widget{
    padding: 20px;
    border-radius: 20px;
    background: white;
    box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
  }
</style>
