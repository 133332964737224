<template>
    <div class="experts-widget">
        <div class="d-flex justify-center align-center" v-if="loading">
          <v-progress-circular  class="mt-5 mx-auto" color="primary"
                               indeterminate width="2"/>
        </div>
      <div v-else>
        <h4 class="mb-5">Эксперты мероприятия</h4>
        <Empty class="mx-auto my-7" title="Эксперты пока не присоединились," description="но мы обязательно их найдем" v-if="!experts.length"/>
        <div v-else>
          <UserLinkComponent class="mr-4 mt-4" :user="expert" v-for="expert in experts" :key="expert.id"/>
        </div>
      </div>
    </div>
</template>

<script>
import Empty from "@/components/Common/Empty";
import UserLinkComponent from "@/components/Profile/UserLinkComponent";
export default {
  name: "EventExpertsWidget",
  components: {UserLinkComponent, Empty},
  data(){
    return {
      loading: true
    }
  },
  computed: {
    experts() {
      return this.$store.state.events.experts;
    },
  },
  mounted() {
    this.$store.dispatch('getExperts', {id: this.$route.params.id}).then(() => {
      this.loading = false;
    })
  }
}
</script>

<style scoped>
 .experts-widget{
   padding: 20px;
   border-radius: 20px;
   border: 1px solid #e5e5e5;
   background: white;
   box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
 }
</style>
