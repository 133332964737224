<template>
  <div style="height: 500px; background: white; overflow: hidden;" class="widget">
    <h4>Чат мероприятия</h4>
    <SmartChatComponent v-if="event.chat_id > 0" :chat_id="event.chat_id" height="300"/>
  </div>
</template>

<script>
import SmartChatComponent from "@/components/Messages/SmartChatComponent.vue";

export default {
  name: "EventChatWidget",
  components: {SmartChatComponent},
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    }
  },
}
</script>

<style scoped>
.widget {
  border: 1px solid #eaeaea;
  border-radius: 20px;
  padding: 20px;
}
</style>
