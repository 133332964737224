<template>
  <div class="application_form">
    <h3>Принять участие</h3>
    <br/>
    <div v-if="$store.getters.isAuth">
      <p>1. Выбери роль в мероприятии</p>
      <br/>
      <v-select v-model="application.event_role_id" :items="$store.state.events.currentEvent.visible_roles" dense
                item-text="role.name"
                item-value="id" label="Выберите тип участия" no-data-text="Нет подходящих ролей"></v-select>

      <template v-if="event.teams_allowed">
        <br/>
        <p>
          2. В мероприятии предполагается командное участие.
          <span v-if="event.type.mnemo === 'opd'">Для участия необходима команда. </span>
        </p>
        <template v-if="!availableTeams.isLoad">
          <v-skeleton-loader
              v-bind="attrs"
              type="list-item-two-line"
          ></v-skeleton-loader>
        </template>
        <template v-else-if="availableTeams.data.length">

          <v-radio-group v-model="type">
            <v-radio
                :value="0"
                label="Моя команда уже участвует"
            ></v-radio>
            <v-radio
                :value="1"
                label="Участвовать вместе с командой"
            ></v-radio>
          </v-radio-group>

          <v-select v-if="type === 0" v-model="selected_team" :items="availableTeams.data" item-text="name"
                    item-value="id"
                    label="Выберите команду">
            <template v-slot:item="data">
              <v-list-item :disabled="!data.item.is_open" v-bind="data.attrs" v-on="data.on">
                <v-list-item-avatar>
                  <v-img :src="data.item.logo"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.members.map((u) => u.name).join(", ") }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </template>
        <template v-if="type === 1">
          <template>
            <template v-if="availableProjects.data.length">
              <v-alert dense dismissible
                       type="info"
              >
                Вы можете выбрать один из проектов на АИС для участия в качестве основы для заявки:
              </v-alert>

              <SelectProjectForEvent :event_id="event.id" v-model="application.project_id"
                                     :items="availableProjects.data" @input="autoCompleteTeam"/>
            </template>
          </template>
          <template v-if="!application.project_id">
            <v-text-field v-model="application.team_name" label="Название команды / проекта"/>
            <v-autocomplete v-model="application.team_participants" :items="availableParticipants.data"
                            :loading="!availableParticipants.isLoad" chips deletable-chips item-text="name"
                            item-value="id" label="Участники"
                            multiple no-data-text="Такого пользователя нет на АИС" outlined>
              <template v-slot:item="data">
                <v-list-item v-bind="data.attrs" v-on="data.on"
                             :disabled="data.item.already_in_event && data.item.teams.length > 0">
                  <v-list-item-avatar>
                    <v-avatar size="24">
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>

                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.already_in_event && data.item.teams.length > 0">Уже участвует
                      в
                      команде {{ data.item.teams[0].name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="data.item.already_in_event">Участвует в мероприятии (без команды)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </template>
        </template>
      </template>

      <v-btn :disabled="!application.event_role_id" :loading="loading" class="mt-3" color="primary" outlined
             rounded small @click="makeApplication">Принять участие
      </v-btn>
    </div>
    <div v-else>
      <p> Для того чтобы принять участие в мероприятии нужно
        войти / зарегистрироваться на платформе</p>
      <v-btn color="primary" outlined rounded small to="/auth">Авторизация</v-btn>
    </div>
  </div>
</template>

<script>
import events from "@/api/events.js";
import SelectProjectForEvent from "@/components/Events/EventParts/Widgets/SelectProjectForEvent.vue";

export default {
  name: "EventApplicationFormWidget",
  components: {SelectProjectForEvent},
  methods: {
    autoCompleteTeam() {
      let project = this.availableProjects.data.filter(p => p.id === this.application.project_id)[0];
      if (project) {
        this.application.team_name = project.title;
        this.application.team_participants = project.users.map((u) => u.id);
      } else {
        this.application.team_name = "";
        this.application.team_participants = [];
      }
    },

    makeApplication() {
      this.loading = true;
      this.$store.dispatch('checkIn', {
        event_id: this.event.id, ...this.application,
        team_id: this.selected_team
      }).then(() => {
        this.$store.dispatch('getEvent', {id: this.event.id}).then(() => {
          this.loading = false;
        });
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    },
  },
  data() {
    return {
      selected_team: null,
      type: 1,
      availableTeams: {
        isLoad: false,
        data: []
      },
      availableProjects: {
        isLoad: false,
        data: []
      },
      availableParticipants: {
        isLoad: false,
        data: []
      },
      application: {
        event_role_id: null,
        project_id: null,
        team_name: "",
        team_participants: []
      },
      loading: false
    }
  },
  mounted() {
    if (this.$store.getters.isAuth) {
      events.getAvailableTeams(this.event.id).then((r) => {
        this.availableTeams.data = r.data;
        this.availableTeams.isLoad = true
      });

      events.getAvailableProjects(this.event.id).then((r) => {
        this.availableProjects.data = r.data;
        this.availableProjects.isLoad = true
      });

      events.getAvailableParticipants(this.event.id).then((r) => {
        this.availableParticipants.data = r.data;
        this.availableParticipants.isLoad = true
      });

      if (this.$store.state.events.currentEvent.visible_roles.length === 1) {
        this.application.event_role_id = this.$store.state.events.currentEvent.visible_roles[0].id;

      }
    }
  }
}
</script>

<style scoped>
.application_form {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}
</style>
