import endpoint from "@/api/endpoint";

export default {

    decline(data) {
        return window.axios.post(endpoint + '/api/invitations/' + data.id + '/decline');
    },

    accept(data) {
        return window.axios.post(endpoint + '/api/invitations/' + data.id + '/accept');
    },

    create(data) {
        return window.axios.post(endpoint + '/api/invitations/create', data);
    },


}
