<template>
  <div class="event_participants_widget">
    <div class="event_participants_widget__title">Участники мероприятия</div>
    <template v-if="participants10.count > 0">
      <div class="event_participants_widget__participants">
        <UserLinkComponent v-for="participant in participants10.participants" :key="participant.id" :user="participant"
                           class="mr-3 mt-3"/>
        <v-chip v-if="participants10.count > 10" class="mt-3">еще {{ participants10.count - 10 }}
          {{ plural(participants10.count - 10, 'человек', 'человека', 'человек') }}
        </v-chip>
      </div>
    </template>
    <template v-else>
      <v-progress-circular :size="14" class="mr-2" color="primary" width="1"/>
    </template>
  </div>
</template>

<script>
import UserLinkComponent from "@/components/Profile/UserLinkComponent.vue";
import plural from '@/plural.js'

export default {
  name: "EventParticipantsWidget",
  data() {
    return {
      loading: true
    }
  },
  components: {UserLinkComponent},
  computed: {
    participants10() {
      return this.$store.state.events.participants_shot
    }
  },
  methods: {
    plural(n, one, two, five) {
      return plural(n, one, two, five)
    }
  },
  mounted() {
    this.$store.dispatch('getEventParticipantsShot', {id: this.$store.state.events.currentEvent.id}).then(() => {
      this.loading = false;
    });
  }
}
</script>

<style scoped>
.event_participants_widget {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}

.event_participants_widget__title {
  font-size: 16px;
  font-weight: 450;
}

.event_participants_widget__participants {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
</style>
