<template>
  <div class="application_status">
    <template v-if="!event.application.approved">
      <h3>Ваше участие</h3>
      <div>
        {{ $moment.utc(event.application.created_at).local().format("DD.MM HH:mm") }} вы успешно подали заявку на
        участие в
        {{ event.name }}
      </div>
    </template>
    <template v-else>
      <h3>Ваше участие</h3>
      <div>
        {{ $moment.utc(event.application.updated_at).local().format("DD.MM HH:mm") }} ваша заявка была одобрена.
        <span v-if="event.is_online">Через {{ timeBeforeStart }} здесь будет ссылка на подключение.</span>
      </div>
    </template>


  </div>
</template>

<script>
export default {
  name: "EventStatusWidget",
  methods: {},
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    },
  },
  data() {
    return {
      timeBeforeStart: ""
    }
  },
  mounted() {
    setInterval(() => {
      this.timeBeforeStart = this.$moment.utc(this.event.start_at).fromNow();
    }, 1000);
  }
}
</script>

<style scoped>
.application_status {
  padding: 20px;
  border-radius: 20px;
  background: white;
  box-shadow: rgba(20, 28, 37, 0.1) 0px 1px 10px;
}
</style>
