<template>
  <CardUI :loading="loading">
    <template v-slot:header>
      <h4>Создать команду</h4>
    </template>
    <template v-slot:body>
      <v-container>
        <v-row>
          <v-col lg="12">
            <h5>Название команды</h5>
            <InputUI v-model="team.name" :errors="errors.name" counter dense outlined placeholder="Название команды"
                     prepend-icon="mdi-account"></InputUI>
            <h5>Описание команды (девиз)</h5>
            <InputUI v-model="team.description" :errors="errors.description" counter dense outlined
                     placeholder="Девиз команды" prepend-icon="mdi-text"></InputUI>

            <v-autocomplete class="mt-4" v-model="team.participants" :items="availableParticipants.data"
                            :loading="!availableParticipants.isLoad" chips deletable-chips item-text="name"
                            item-value="id" label="Участники"
                            multiple no-data-text="Такого пользователя нет на АИС" outlined>
              <template v-slot:item="data">
                <v-list-item v-bind="data.attrs" v-on="data.on"
                             :disabled="data.item.already_in_event && data.item.teams.length > 0">
                  <v-list-item-avatar>
                    <v-avatar size="24">
                      <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>

                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                    <v-list-item-subtitle v-if="data.item.already_in_event && data.item.teams.length > 0">Уже участвует
                      в
                      команде {{ data.item.teams[0].name }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else-if="data.item.already_in_event">Участвует в мероприятии (без команды)
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>

            <h5>Логотип</h5>

            <FileUpload v-model="files" class="mt-3 mb-3"/>
          </v-col>

        </v-row>
      </v-container>
      <v-card-actions>
        <ButtonUI text type="secondary" @click="$emit('close')">Закрыть</ButtonUI>
        <div style="width: 2em"></div>
        <ButtonUI :disabled="loading" :loading="loading" color="blue darken-3" text @click="save">Создать</ButtonUI>
      </v-card-actions>
    </template>
  </CardUI>
</template>

<script>
import CardUI from "@/components/UI/CardUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import FileUpload from "@/components/Common/FileUpload.vue";
import events from "@/api/events.js";

export default {
  name: "TeamCreateEditComponent",
  components: {ButtonUI, InputUI, CardUI, FileUpload},
  data() {
    return {
      files: [],
      errors: {},
      team: {
        name: '',
        description: '',
        logo: null,
        participants: []
      },
      availableParticipants: {
        data: [],
        isLoad: false
      },
      loading: false,
    }
  },
  mounted() {
    events.getAvailableParticipants(this.$store.state.events.currentEvent.id).then((r) => {
      this.availableParticipants.data = r.data;
      this.availableParticipants.isLoad = true
    });
  },
  methods: {
    save() {
      this.loading = true;
      this.$store.dispatch('createTeam', {
            event_id: this.$store.state.events.currentEvent.id,
            name: this.team.name, description: this.team.description, file_id: this.files[0] && this.files[0].id,
            participant_ids: this.team.participants
          }
      ).then(() => {
        this.loading = false;
        this.$emit('close');
      }).finally((e) => {
        this.$store.dispatch('getEvent', {id: this.$route.params.id}).then(() => {
          this.loading = false;
          this.errors = e.response.data.errors;
          this.$store.dispatch('getEvent')
        })

      })

    }
  }
}
</script>

<style scoped>

</style>
