<template>
  <div>
    <h3>Приглашения</h3>
    <v-banner :key="invitation.id" v-for="invitation in event.invitations"
              class="mt-2"
              color="primary"
              dark
              rounded
              shaped
              transition="slide-y-transition"
              two-lines
    >
      {{ invitation.by_user.name }} приглашает Вас в
      <span v-if="invitation.team_id > 0">команду {{ invitation.team.name }} </span>
      <span v-if="invitation.event_id > 0"> стать участником мероприятия {{ invitation.event.name }} </span>
      <template v-slot:icon>
        <v-avatar>
          <v-img :src="invitation.by_user.avatar"/>
        </v-avatar>
      </template>
      <template v-slot:actions="{ dismiss }">
        <v-btn
            color="white"
            text
            @click="acceptInvitation(invitation.id)"
            :loading="loading"
        >
          Принять
        </v-btn>
        <v-btn :loading="loading"
               color="gray"
               text
               @click="declineInvitation(invitation.id)"
        >
          Отклонить
        </v-btn>
      </template>
    </v-banner>
  </div>
</template>

<script>
import invitations from "@/api/invitations.js";

export default {
  name: "EventInvitationsWidget",
  computed: {
    event() {
      return this.$store.state.events.currentEvent;
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    acceptInvitation(inv_id) {
      this.loading = true;
      invitations.accept({id: inv_id}).then(() => {
        this.$store.dispatch('getEvent', {id: this.event.id}).then(() => {
          this.loading = false;
        })
      });
    },

    declineInvitation(inv_id) {
      this.loading = true;
      invitations.decline({id: inv_id}).then(() => {
        this.$store.dispatch('getEvent', {id: this.event.id}).then(() => {
          this.loading = false;
        })
      });
    },
  }
}
</script>

<style scoped>

</style>
