<template>
  <div :key="team.id" class="team">
    <div class="team__avatar-wrapper">
      <v-img aspect-ratio="1" :alt="team.name" :src="team.logo" class="team__avatar"/>
    </div>
    <div class="team__content">
      <div class="team__name max-2-lines">{{ team.name }}</div>
      <div class="team__description max-3-lines">{{ team.description }}</div>
      <div v-if="team.slot" class="team__slot">{{ $moment.utc(team.slot.start_at).local().format('dd DD.MM HH:mm')}}</div>
      <div class="team__features mt-1">
        <span v-if="team.in_team" class="team__feature team__feature_my">вы в команде</span>
        <span v-if="team.can_join_to_team" class="team__feature team__feature_join">можно присоединиться</span>
      </div>
    </div>
    <v-spacer/>
    <div class="team__action ml-2">
      <v-icon>mdi-arrow-right-circle</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamListItem",
  props: ['team']
}
</script>

<style scoped>
.team {
  background: #edf0fc;
  padding: 10px;
  border-radius: 10px;
  font-size: 14px;
  display: flex;
  align-items: start;
}

.team:hover {
  cursor: pointer;
  background: #ccd8f6;
}

.team__name {
  font-size: 17px;
  font-weight: bold;
}

.max-2-lines{
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-3-lines{
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.team__avatar-wrapper {
  min-width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 1em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.team__avatar {

}

.team__feature_my {
  background: #a8ffb1;
}

.team__feature_join {
  background: #a8ebff;
}

.team__feature {

  padding: 0.5em;
  font-size: 10px;
  border-radius: 0.5em;
}
</style>
